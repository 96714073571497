import { createApp } from 'vue'
import axios from 'axios'  //如果需要axios，请引入
import ElementPlus from 'element-plus'
import VFormRender from 'vform3-builds/dist/render.umd.js'
import 'element-plus/dist/index.css'
import 'vform3-builds/dist/render.style.css'
import App from './App.vue';

const app = createApp(App)

app.use(ElementPlus)
app.use(VFormRender)
window.axios = axios
app.mount('#app')

