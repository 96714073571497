<template>
    <div>
        <h2 style="text-align: left;">熙熙哥的调查问卷</h2>
        <div style="width:100%;border-top:1px solid #ccc;height:1px;margin-bottom: 20px;"></div>
        <v-form-render :form-json="formJson" :form-data="formData" :option-data="optionData" ref="vFormRef">
        </v-form-render>
        <el-button style="width:100%;font-size: 23px;height:60px;" type="primary" @click="submitForm">提交</el-button>

        <!--      <v-form-render :form-json="formJson" :form-data="formData" :option-data="optionData" ref="vFormRef">-->
        <!--      </v-form-render>-->
        <!--      <el-button type="primary" @click="submitForm">Submit</el-button>-->
    </div>
</template>

<script setup>
import {ref, reactive} from 'vue'
import {ElMessage} from 'element-plus'
import {ElMessageBox} from 'element-plus'

/* 注意：formJson是指表单设计器导出的json，此处演示的formJson只是一个空白表单json！！ */
const formJson = reactive(
    {
        "widgetList": [
            {
                "type": "static-text",
                "icon": "static-text",
                "formItemFlag": false,
                "options": {
                    "name": "statictext94178",
                    "columnWidth": "200px",
                    "hidden": false,
                    "textContent": "1. 性别",
                    "customClass": "",
                    "onCreated": "",
                    "onMounted": "",
                    "label": "static-text"
                },
                "id": "statictext94178"
            },
            {
                "type": "radio",
                "icon": "radio-field",
                "formItemFlag": true,
                "options": {
                    "name": "1",
                    "label": "radio",
                    "labelAlign": "label-left-align",
                    "defaultValue": "",
                    "columnWidth": "200px",
                    "size": "",
                    "displayStyle": "block",
                    "buttonStyle": false,
                    "border": false,
                    "labelWidth": null,
                    "labelHidden": true,
                    "disabled": false,
                    "hidden": false,
                    "optionItems": [
                        {
                            "label": "男",
                            "value": 1
                        },
                        {
                            "label": "女",
                            "value": 2
                        }
                    ],
                    "required": true,
                    "validation": "",
                    "validationHint": "所有问题为必填项",
                    "customClass": [],
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": "",
                    "requiredHint": "该问题为必填"
                },
                "id": "1"
            },
            {
                "type": "static-text",
                "icon": "static-text",
                "formItemFlag": false,
                "options": {
                    "name": "statictext46881",
                    "columnWidth": "200px",
                    "hidden": false,
                    "textContent": "2. 年龄段",
                    "customClass": "",
                    "onCreated": "",
                    "onMounted": "",
                    "label": "static-text"
                },
                "id": "statictext46881"
            },
            {
                "type": "radio",
                "icon": "radio-field",
                "formItemFlag": true,
                "options": {
                    "name": "2",
                    "label": "radio",
                    "labelAlign": "label-left-align",
                    "defaultValue": "",
                    "columnWidth": "200px",
                    "size": "",
                    "displayStyle": "block",
                    "buttonStyle": false,
                    "border": false,
                    "labelWidth": null,
                    "labelHidden": true,
                    "disabled": false,
                    "hidden": false,
                    "optionItems": [
                        {
                            "value": 1,
                            "label": "00后"
                        },
                        {
                            "value": 2,
                            "label": "95后"
                        },
                        {
                            "value": 3,
                            "label": "90后"
                        },
                        {
                            "value": 4,
                            "label": "80后"
                        },
                        {
                            "value": 5,
                            "label": "70后"
                        }
                    ],
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": [],
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": "",
                    "requiredHint": "该问题为必填"
                },
                "id": "2"
            },
            {
                "type": "static-text",
                "icon": "static-text",
                "formItemFlag": false,
                "options": {
                    "name": "statictext96809",
                    "columnWidth": "200px",
                    "hidden": false,
                    "textContent": "3. 喜欢什么样的歌（多选）",
                    "customClass": "",
                    "onCreated": "",
                    "onMounted": "",
                    "label": "static-text"
                },
                "id": "statictext96809"
            },
            {
                "type": "checkbox",
                "icon": "checkbox-field",
                "formItemFlag": true,
                "options": {
                    "name": "3",
                    "label": "checkbox",
                    "labelAlign": "label-left-align",
                    "defaultValue": [],
                    "columnWidth": "200px",
                    "size": "",
                    "displayStyle": "block",
                    "buttonStyle": false,
                    "border": false,
                    "labelWidth": null,
                    "labelHidden": true,
                    "disabled": false,
                    "hidden": false,
                    "optionItems": [
                        {
                            "value": 1,
                            "label": "emo歌"
                        },
                        {
                            "value": 2,
                            "label": "情歌"
                        },
                        {
                            "value": 3,
                            "label": "甜歌"
                        },
                        {
                            "value": 4,
                            "label": "励志歌"
                        },
                        {
                            "value": 5,
                            "label": "古风国风"
                        },
                        {
                            "value": 6,
                            "label": "说唱"
                        },
                        {
                            "value": 7,
                            "label": "电音"
                        },
                        {
                            "value": 8,
                            "label": "摇滚"
                        },
                        {
                            "value": 9,
                            "label": "民谣"
                        }
                    ],
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": "",
                    "requiredHint": "该问题为必填"
                },
                "id": "3"
            },
            {
                "type": "static-text",
                "icon": "static-text",
                "formItemFlag": false,
                "options": {
                    "name": "statictext96809",
                    "columnWidth": "200px",
                    "hidden": false,
                    "textContent": "4. 听歌的时候会更在意哪一部分（多选）",
                    "customClass": "",
                    "onCreated": "",
                    "onMounted": "",
                    "label": "static-text"
                },
                "id": "statictext96809"
            },
            {
                "type": "checkbox",
                "icon": "checkbox-field",
                "formItemFlag": true,
                "options": {
                    "name": "4",
                    "label": "checkbox",
                    "labelAlign": "label-left-align",
                    "defaultValue": [],
                    "columnWidth": "200px",
                    "size": "",
                    "displayStyle": "block",
                    "buttonStyle": false,
                    "border": false,
                    "labelWidth": null,
                    "labelHidden": true,
                    "disabled": false,
                    "hidden": false,
                    "optionItems": [
                        {
                            "value": 1,
                            "label": "歌词"
                        },
                        {
                            "value": 2,
                            "label": "旋律"
                        },
                        {
                            "value": 3,
                            "label": "人声"
                        },
                        {
                            "value": 4,
                            "label": "编曲伴奏"
                        },
                        {
                            "value": 5,
                            "label": "说不出的感觉"
                        }
                    ],
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "onCreated": "",
                    "onMounted": "",
                    "onChange": "",
                    "onValidate": "",
                    "requiredHint": "该问题为必填"
                },
                "id": "4"
            },
            {
                "type": "static-text",
                "icon": "static-text",
                "formItemFlag": false,
                "options": {
                    "name": "statictext85090",
                    "columnWidth": "200px",
                    "hidden": false,
                    "textContent": "5. 为什么会关注熙熙哥",
                    "customClass": "",
                    "onCreated": "",
                    "onMounted": "",
                    "label": "static-text"
                },
                "id": "statictext85090"
            },
            {
                "type": "input",
                "icon": "text-field",
                "formItemFlag": true,
                "options": {
                    "name": "5",
                    "label": "input",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "请留下您的原因",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": true,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": "",
                    "requiredHint": "该问题为必填"
                },
                "id": "5"
            },
            {
                "type": "static-text",
                "icon": "static-text",
                "formItemFlag": false,
                "options": {
                    "name": "statictext85090",
                    "columnWidth": "200px",
                    "hidden": false,
                    "textContent": "6. 给熙熙哥一些建议",
                    "customClass": "",
                    "onCreated": "",
                    "onMounted": "",
                    "label": "static-text"
                },
                "id": "statictext85090"
            },
            {
                "type": "input",
                "icon": "text-field",
                "formItemFlag": true,
                "options": {
                    "name": "6",
                    "label": "input",
                    "labelAlign": "",
                    "type": "text",
                    "defaultValue": "",
                    "placeholder": "请留下您的宝贵建议",
                    "columnWidth": "200px",
                    "size": "",
                    "labelWidth": null,
                    "labelHidden": true,
                    "readonly": false,
                    "disabled": false,
                    "hidden": false,
                    "clearable": true,
                    "showPassword": false,
                    "required": true,
                    "validation": "",
                    "validationHint": "",
                    "customClass": "",
                    "labelIconClass": null,
                    "labelIconPosition": "rear",
                    "labelTooltip": null,
                    "minLength": null,
                    "maxLength": null,
                    "showWordLimit": false,
                    "prefixIcon": "",
                    "suffixIcon": "",
                    "appendButton": false,
                    "appendButtonDisabled": false,
                    "buttonIcon": "el-icon-search",
                    "onCreated": "",
                    "onMounted": "",
                    "onInput": "",
                    "onChange": "",
                    "onFocus": "",
                    "onBlur": "",
                    "onValidate": "",
                    "requiredHint": "该问题为必填"
                },
                "id": "6"
            }
        ],
        "formConfig": {
            "modelName": "formData",
            "refName": "vForm",
            "rulesName": "rules",
            "labelWidth": 158,
            "labelPosition": "top",
            "size": "large",
            "labelAlign": "label-left-align",
            "cssCode": "",
            "customClass": [],
            "functions": "",
            "layoutType": "H5",
            "jsonVersion": 3,
            "onFormCreated": "",
            "onFormMounted": "",
            "onFormDataChange": ""
        }
    }
)
const formData = reactive({})
const optionData = reactive({})
const vFormRef = ref(null)

const submitForm = () => {
    vFormRef.value.getFormData().then(formData => {
        // Form Validation OK
        // alert(JSON.stringify(formData))
        const content = JSON.stringify(formData)
        window.axios.post(`http://service.postmusic.cn/api/newp/questionnaires`, {content})
            .then(response => {
                const {data} = response
                if (data.code === 0) {
                    console.log(response)
                } else {
                    alert(data.msg);
                }
            }).catch(error => {

            alert(error);
        })

        openBox()
    }).catch(error => {
        // Form Validation failed
        // ElMessage.error(error)
        console.log(error)
        ElMessage.error('所有问题为必填项')
    })
}

const openBox = () => {
    ElMessageBox.alert(
        '<img src="https://post-dog-images.postmusic.cn/WechatIMG1510.png" style="width:300px;height:370px;" />',
        {
            showConfirmButton: false,
            showCancelButton: false,
            showClose: false,
            roundButton: false,
            dangerouslyUseHTMLString: true,

        }
    )
}


</script>
<style>
.el-radio:last-child {
    margin-right: 32px;
}

.el-checkbox {
    text-align: left;
}

.static-content-item {
    text-align: left;
    font-size: 22px;
}

/*.static-content-item:before{*/
/*    content: "*";*/
/*    color: var(--el-color-danger);*/
/*    margin-right: 4px;*/
/*}*/
.el-form-item__label:before {
    display: none;
}

.static-content-item div:after {
    content: "*";
    color: var(--el-color-danger);
    margin-right: 4px;
}

.el-radio {
    font-size: 20px;
    padding-left: 25px;
}

.el-checkbox {
    padding-left: 25px;
}

.el-radio__label, .el-checkbox__label {
    font-size: 17px;
}

.el-input__inner {
    height: 37px;
    font-size: 17px;
    padding-left: 13px;
}

.el-message-box__header {
    opacity: 0;
}

/*.static-content-item:last-child {*/
/*    color:red;*/
/*}*/

.el-message-box__content {
    padding: 0px;
}

.el-message-box {
    width: 300px;
    height: 360px;
    background-color: rgb(119 119 119 / 0%);;
    border: none;
    box-shadow: none;
}
.el-overlay.is-message-box .el-overlay-message-box{
    bottom:100px;
}

</style>

